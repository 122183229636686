/* eslint-disable import/order */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import axios from 'axios/index'
import {
  Box,
  Divider,
  Grid,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import config from '../../../config'
import styles from '../../../resources/theme/global'

import { Filter } from './Filter'
import { format } from 'date-fns'
import CardSection from './CardSection'
import { fetchDashboard } from '../DashboardLeadsActions'
import { useDispatch } from 'react-redux'
import colors from '../../../assets/colors'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'
import {
  mdiAccountFilter,
  mdiAccountMultipleCheck,
  mdiCalendarMultipleCheck,
  mdiCurrencyUsd,
  mdiPercent,
} from '@mdi/js'
import AditionalBox from './AditionalBox'
import Icon from '@mdi/react'
import CardKnow from './CardKnow'
import Chart from 'react-google-charts'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  boxChart: {
    border: `1px solid ${colors.graylight}`,
    height: '100%',
    padding: '10px',
    borderRadius: '10px',
  },
  qtdOriginLeads: {
    color: colors.primary,
    fontSize: '40px',
    display: 'flex',
    textAlign: 'start',
    alignItems: 'center',
    fontWeight: 700,
  },
})

const LeadsDashboard = styles => {
  const dispatch = useDispatch()
  const style = useStyles()
  const { classes } = styles
  const [chartData, setChartData] = useState([])

  const [monthVision, setMonthVision] = useState(false)

  const date = new Date()

  // const getFirstDayOfPeriod = date => {
  //   const [year, month] = [date.getFullYear(), date.getMonth()]
  //   return new Date(year, month, 1)
  // }

  // const getLastDayOfPeriod = date => {
  //   const lastDayOfMonth = new Date(date)
  //   lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
  //   lastDayOfMonth.setDate(0)
  //   return lastDayOfMonth
  // }

  const [filters, setFilters] = useState({
    date_start: format(date, 'yyyy-MM-dd '),
    date_end: format(date, 'yyyy-MM-dd '),
  })

  const [data, setData] = useState([])

  const fetchDashboardData = async event => {
    const resp = await dispatch(fetchDashboard(filters))

    if (resp) {
      setData(resp)
    }
  }

  const initGraphicData = () => {
    if (!data?.know_way_metrics?.know_ways?.length > 0) return setChartData([])

    setChartData([])
    data?.know_way_metrics?.know_ways?.map(origin => {
      setChartData(prevState => [...prevState, [origin?.name, origin?.value]])
    })
  }

  useEffect(() => {
    fetchDashboardData()
  }, [filters])

  useEffect(() => {
    initGraphicData()
  }, [data])

  return (
    <Grid container style={{ marginBottom: '16px' }}>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Dashboard Leads
          </Typography>
        </Grid>

        <Grid item xs container justifyContent="flex-end">
          <PeriodFilter
            filters={filters}
            setFilters={setFilters}
            onChangePeriod={key => {
              setMonthVision(key != 2)
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6} style={{ marginTop: '1em' }}>
        <Grid item sm={9}>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12}>
              <CardSection
                data={data?.leads_period}
                title="Leads no período"
                isMonetary
                icon={mdiAccountMultipleCheck}
                isLinkCard
                classes={classes}
                finalDate={filters.date_end}
                startDate={filters.date_start}
              />
            </Grid>

            <Grid item xs={12}>
              <CardSection
                data={data?.agendaments_metrics?.agendaments}
                title="Agendamentos no período"
                isMonetary
                icon={mdiCalendarMultipleCheck}
                isLinkRelatory
                classes={classes}
                finalDate={filters.date_end}
                startDate={filters.date_start}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Grid container className={style.boxChart}>
            <Chart
              chartType="PieChart"
              width="100%"
              data={[['Como conheçeu', 'Quantidade'], ...chartData]}
              height="100%"
              options={{
                is3D: 'true',
                legend: {
                  position: 'none',
                },
                chartArea: {
                  width: '100%',
                  height: '100%',
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '1em' }}>
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={3}>
              <AditionalBox
                title="CAC"
                isDecimal
                top="R$"
                value={data?.marketing_metrics?.cac}
                disabled={monthVision}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AditionalBox
                title="ROAS"
                isDecimal
                disabled={monthVision}
                value={data?.marketing_metrics?.roas}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AditionalBox
                title="Média de Desconto"
                isDecimal
                titleFont="17px"
                suffix="%"
                value={data?.agendaments_metrics?.agendamentsTicket?.value}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AditionalBox
                top="R$"
                title="Ticket médio"
                isDecimal
                titleFont="17px"
                icon={mdiCurrencyUsd}
                value={data?.ticket?.value}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                style={{
                  fontSize: '20px',
                  borderTop: '2px solid',
                  borderColor: colors.secondary,
                  padding: '20px 0px 0px 0px',
                }}
                color={colors.primary}
              >
                Origem dos leads
              </Typography>
            </Grid>

            {/* cpa - cpl - roas - somente mensal */}
            {data?.know_way_metrics?.know_ways?.map(origin => (
              <CardKnow
                name={origin?.name}
                orcament={origin?.orcament}
                qtd={origin?.value}
                convert={origin?.converted}
                qtdConvert={origin?.qtdConverted}
                notConvert={origin?.notConverted}
                qtdNotConvert={origin?.qtdNotConverted}
                cpl={origin?.cpl}
                cpa={origin?.cpa}
                roas={origin?.roas}
                disabled={monthVision}
              />
            ))}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            borderTop: '1px dashed',
            borderBottom: '1px dashed',
            borderColor: colors.graylight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '12px',
          }}
        >
          <Box>
            <Icon path={mdiAccountFilter} color={colors.graylight} size={2} />
          </Box>

          <Typography
            style={{ fontSize: '17px', lineHeight: '18px', fontWeight: 'bold' }}
          >
            Total de leads <br /> no período
          </Typography>

          <Divider orientation="vertical" flexItem />

          <Typography
            style={{
              color: colors.primary,
              fontSize: '20px',
              fontWeight: '700',
            }}
          >
            {data?.know_way_metrics?.leads_on_period}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(LeadsDashboard)
