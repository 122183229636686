import { Box, Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import colors from '../../../assets/colors'
import CountUp from 'react-countup'
import Icon from '@mdi/react'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  aditionalBox: {
    padding: '28px',
    color: colors.primary,
    fontSize: '12px',
    lineHeight: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    border: '1px solid',
    borderColor: colors.graylight,
    borderRadius: '10px',
  },
  title: {
    color: colors.primary,
    fontSize: '30px',
    display: 'flex',
    textAlign: 'start',
    alignItems: 'center',
    fontWeight: 700,
    lineHeight: '17px',
  },
})

const AditionalBox = ({
  top,
  title,
  value,
  icon,
  titleFont = '30px',
  suffix = null,
  isDecimal = false,
  disabled = false,
  decimals = 2,
}) => {
  const classes = useStyles()

  const contUp = (money, decimals = 2) => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={decimals}
        decimal=","
        // prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid container className={[classes.root, classes.aditionalBox]}>
      {icon && (
        <Box>
          <Icon path={icon} color={colors.graylight} size={2} />
        </Box>
      )}

      <Typography className={classes.title} style={{ fontSize: titleFont }}>
        {title}
      </Typography>

      <Divider orientation="vertical" flexItem />

      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography style={{ lineHeight: '12px' }}>
          {!disabled && top}
        </Typography>
        <Typography
          style={{
            color: colors.primary,
            fontSize: '20px',
            fontWeight: '700',
          }}
        >
          {disabled ? 'N/A' : isDecimal ? contUp(value, decimals) : value}

          {!disabled && suffix && suffix}
        </Typography>
      </Box>
    </Grid>
  )
}

export default AditionalBox
