import React from 'react'
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import { mdiCurrencyUsd } from '@mdi/js'
import CountUp from 'react-countup'

const useStyles = makeStyles({
  root: {
    border: `1px solid ${colors.graylight}`,
    padding: '8px 16px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    color: colors.primary,
  },
  paper: {
    color: colors.primary,
    fontSize: '12px',
  },
  number: {
    fontWeight: '700',
    fontSize: '20px',
  },
  value: {
    fontWeight: '700',
    fontSize: '12px',
  },
})

const CardKnow = ({
  name,
  qtd,
  orcament,
  qtdConvert,
  convert,
  qtdNotConvert,
  notConvert,
  cpl = 0,
  cpa = 0,
  roas = 0,
  disabled = false,
  decimals = 2,
}) => {
  const classes = useStyles()

  const contUp = (money, decimals = 2) => {
    return (
      <CountUp
        end={money}
        duration={0.9}
        decimals={decimals}
        decimal=","
        prefix="R$"
        separator="."
      />
    )
  }

  return (
    <Grid item sm={4} xs={12}>
      <Box className={classes.root}>
        <Box
          style={{
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderBottom: `1px solid ${colors.graylight}`,
          }}
        >
          <Typography>{name}</Typography>
          <Box
            style={{
              display: 'flex',
              gap: '4px',
              fontWeight: 'bold',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {qtd}
            </Typography>
            LEADS
          </Box>
        </Box>

        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px dashed ${colors.graylight}`,
            paddingBottom: '6px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
            }}
          >
            <Typography style={{ lineHeight: '13px' }}>
              Total de <br />
              Orçamentos
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {contUp(orcament)}
            </Typography>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '12px',
              gap: '4px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '12px',
              }}
            >
              <Typography style={{ lineHeight: '13px' }}>
                {qtdNotConvert} Leads não convertidos
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {contUp(notConvert)}
              </Typography>
            </Box>

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontSize: '12px',
              }}
            >
              <Typography style={{ lineHeight: '13px' }}>
                {qtdConvert} Leads convertidos
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {contUp(convert)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Typography>CPA: {disabled ? 'N/A' : contUp(cpa, 1)}</Typography>
          <Typography>CPL: {disabled ? 'N/A' : contUp(cpl, 1)}</Typography>
          <Typography>ROAS: {disabled ? 'N/A' : `${roas}`}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default CardKnow
