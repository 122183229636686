/* eslint-disable import/order */
/* eslint-disable camelcase */
import {
  DialogActions,
  FormControlLabel,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format, setHours } from 'date-fns'
import axios from 'axios'
import ReactInputMask from 'react-input-mask'

import config from '../../../config'
import { SNACKBAR } from '../../main/MainActions'
import SelectField from '../../components/SelectField'
import styles from '../../../resources/theme/users'
import { findCep } from '../LaundryClientActions'
import { mdiProgressUpload } from '@mdi/js'
import Icon from '@mdi/react'
import colors from '../../../assets/colors'
import ufs from '../../../util/uf'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  control: {
    padding: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1em',
  },
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  logo: {
    objectFit: 'cover',
    width: '169px',
    height: '32px',
    borderRadius: '10px',
  },
  logoValidate: {
    fontSize: '12px',
    color: colors.graylight,
  },
  btnSalvar: {
    width: '101px',
    height: '30px',
    borderRadius: '16px',
    backgroundColor: colors.primary,
    color: '#FFFF',
    padding: '0 12px',
    margin: 0,
    fontSize: '14px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: 0.6,
    },
  },
}))

function Form(props) {
  const classes = useStyles()
  // const plans = useSelector(state => state.laundryClient.plans)
  const { data, onClose, onSubmit, notShowPost, children, formEdit } = props
  const dispatch = useDispatch()

  const [status, setStatus] = useState()

  const inputRef = useRef()

  const [form, updateForm] = useState({
    id: '',
    name: '',
    logo: '',
    status: 1,
    theme_color: '#93c3ea',
    cep: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    uf: '',
    phone: '',
    cpf_cnpj: '',
    email: '',
    isFormEdit: formEdit || false,
  })

  const [logoValidate, setLogoValidate] = useState({
    invalidType: '',
  })

  const handleChangeLogo = event => {
    const { files } = event.target

    if (!files[0]) {
      return
    }

    const image = event.target.files[0]
    const load = new FileReader()

    load.onload = event => {
      updateForm(prev => ({
        ...prev,
        logoPath: event.target.result,
      }))
    }

    if (image) {
      load.readAsDataURL(image)
    }

    if (!['image/png', 'image/jpeg']?.includes(event.target.files[0].type)) {
      updateForm(prev => ({
        ...prev,
        logo: null,
      }))

      return setLogoValidate({
        ...logoValidate,
        invalidType: 'Tipo de arquivo inválido',
      })
    }

    updateForm(prev => ({
      ...prev,
      logo: event.target.files[0],
    }))

    setLogoValidate({
      ...logoValidate,
      invalidType: '',
    })
  }

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  const updateField = React.useCallback(name => e => {
    const { value } = e.target

    updateForm({
      ...form,
      [name]: value,
    })
  })

  const updateFields = React.useCallback(
    (name, value) => {
      updateForm({
        ...form,
        [name]: value,
      })
    },
    [form]
  )

  const buscaCEP = async () => {
    const cep = form.cep?.replace(/[^\d]/g, '')

    if (cep?.length == 8) {
      const resp = await axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response?.data)

      updateForm({
        ...form,
        city: resp?.localidade,
        uf: resp?.uf,
        street: resp?.logradouro,
        neighborhood: resp?.bairro,
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit && onSubmit(form)
  }

  useEffect(() => {
    if (status) return

    const allStatus = async () => {
      const response = await axios.get(
        `${config.API_URL}/string-values?path=laundry_client`
      )
      if (response) {
        setStatus(response.data?.data)
      }
    }

    allStatus()
  }, [data, formEdit])

  useEffect(() => {
    if (!data) return

    updateForm(prev => ({
      ...prev,
      ...data,
    }))
  }, [data])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {form?.logo && !form?.logoPath && (
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={`data:image/png;base64,${form?.logo}`}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              {form?.logoPath && (
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Box className={classes.boxLogo}>
                      <img
                        alt="Logo"
                        className={classes.logo}
                        src={form?.logoPath}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid item sm={4} xs={12} lg={3}>
                <TextField
                  onChange={updateField('name')}
                  label="Nome"
                  fullWidth
                  required
                  value={form.name}
                />
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <TextField
                  onChange={updateField('email')}
                  label="E-mail"
                  fullWidth
                  required
                  value={form.email}
                />
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <ReactInputMask
                  mask={
                    form.phone?.replace(/[^\d]/g, '').length <= 10
                      ? '(99) 9999-9999?'
                      : '(99) 99999-9999'
                  }
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                  maskChar=""
                  value={form.phone}
                  onChange={e => {
                    updateForm({
                      ...form,
                      phone: e.target.value.replace(/[^\d]/g, ''),
                    })
                  }}
                >
                  {inputProps => (
                    <TextField {...inputProps} label="Telefone" fullWidth />
                  )}
                </ReactInputMask>
              </Grid>

              <Grid item sm={4} xs={12} lg={3}>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  placeholder="(__) _____-____"
                  maskChar=""
                  required
                  value={form?.cell_phone || ' '}
                  onChange={e => {
                    updateForm({
                      ...form,
                      cell_phone: e.target.value.replace(/[^\d]/g, ''),
                    })
                  }}
                >
                  {inputProps => (
                    <TextField {...inputProps} fullWidth label="Celular" />
                  )}
                </ReactInputMask>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '12px' }}>
          <Grid item xs={12} container spacing={2}>
            <Grid item sm={3} xs={12}>
              <ReactInputMask
                mask="99.999-999"
                maskChar=""
                value={form.cep}
                onChange={updateField('cep')}
                onBlur={() => buscaCEP()}
              >
                {inputProps => (
                  <TextField {...inputProps} label="CEP" fullWidth required />
                )}
              </ReactInputMask>
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                onChange={updateField('street')}
                label="Rua"
                required
                fullWidth
                value={form.street}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('number')}
                label="Número"
                fullWidth
                required
                value={form.number}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('complement')}
                label="Complemento"
                fullWidth
                value={form.complement}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('neighborhood')}
                label="Bairro"
                fullWidth
                required
                value={form.neighborhood}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TextField
                onChange={updateField('city')}
                label="Cidade"
                fullWidth
                required
                value={form.city}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <SelectField
                options={ufs}
                setForm={updateFields}
                data={form.uf}
                name="uf"
                required
                label="UF"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ReactInputMask
                    mask={
                      form.cpf_cnpj?.replace(/[^\d]/g, '').length <= 11
                        ? '999.999.999-99?'
                        : '99.999.999/9999-99'
                    }
                    maskChar=""
                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                    value={form.cpf_cnpj}
                    onChange={updateField('cpf_cnpj')}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        validators={[
                          'matchRegexp:^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$',
                        ]}
                        errorMessages={['CPF/CNPJ Inválido']}
                        label="CPF/CNPJ"
                        required
                        fullWidth
                      />
                    )}
                  </ReactInputMask>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={form.status}
                      onChange={updateField}
                      label="status"
                      name="status"
                      required
                      // renderValue={value => `${value}`}
                    >
                      {status?.map(item => {
                        return (
                          <MenuItem value={item?.var_id} key={item?.id}>
                            <em>{item?.value}</em>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>
              <input
                ref={inputRef}
                name="logo"
                type="file"
                accept="image/*"
                onChange={handleChangeLogo}
                style={{ display: 'none' }}
              />
              <IconButton
                onClick={handleChooseFile}
                className={classes.uploadLogo}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon path={mdiProgressUpload} size={1} />
                    Upload Logo
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.logoValidate}>
                      {logoValidate?.invalidType || 'Logo (169 x 32)'}
                    </Typography>
                  </Grid>
                </Grid>
              </IconButton>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <TextField
                maxRows={10}
                placeholder="Descrição"
                name="description"
                value={form.description == 'null' ? '' : form.description}
                onChange={updateField('description')}
                multiline
                fullWidth
                variant="outlined"
                sx={{ margin: '1em' }}
              />
            </Grid>
          </Grid>
        </Grid>

        <>{children}</>

        <Box className={classes.footer}>
          <Button color="primary" type="submit" className={classes.btnSalvar}>
            Salvar
          </Button>
        </Box>
      </form>
    </>
  )
}

export default withStyles(styles)(Form)
