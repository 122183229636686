import React from 'react'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import BaseFilterModal from '../../../../components/hooks/BaseFilterModal'
import colors from '../../../../assets/colors'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../../components/hooks/BaseTableComponents'
import { useSelector } from 'react-redux'
import { exportFormatedMoney } from '../../../../util/utils'

const RoiInformationModal = ({ isOpen, handleClose }) => {
  const data = useSelector(state => state.financialDashboard)

  return (
    <BaseFilterModal
      title="Detalhes do ROI"
      open={isOpen}
      handleOpen={handleClose}
      hiddenHelpButton
    >
      <Grid item xs={12} container style={{ width: '640px' }}>
        <Grid container>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              width: '100%',
            }}
          >
            <Box
              style={{
                maxHeight: '300px',
                display: 'flex',
                width: '100%',
                gap: '8px',
                flexDirection: 'column',
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  color: colors.primary,
                  fontWeight: 'bold',
                }}
              >
                Serviços realizados - {exportFormatedMoney(data?.roi?.receipts)}
              </Typography>

              {/* <Box style={{ flex: 1, overflowY: 'auto', padding: '10px 0px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCellHeader>OS</StyledTableCellHeader>
                      <StyledTableCellHeader>Cliente</StyledTableCellHeader>
                      <StyledTableCellHeader>
                        Como conheceu
                      </StyledTableCellHeader>
                      <StyledTableCellHeader>Valor</StyledTableCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.roi?.services?.map((service, index) => (
                      <TableRow key={index}>
                        <StyledTableCellBody>
                          {service?.service_id}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {service?.client_name}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {service?.knowWay}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {exportFormatedMoney(service?.billing_value || 0)}
                        </StyledTableCellBody>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box> */}
            </Box>
            <Box
              style={{
                maxHeight: '300px',
                display: 'flex',
                width: '100%',
                gap: '8px',
                flexDirection: 'column',
              }}
            >
              <Typography
                style={{
                  fontSize: '14px',
                  color: colors.primary,
                  fontWeight: 'bold',
                }}
              >
                Investimentos - {exportFormatedMoney(data?.roi?.investment)}
              </Typography>

              {/* <Box style={{ flex: 1, overflowY: 'auto', padding: '10px 0px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCellHeader>
                        Contato Financeiro
                      </StyledTableCellHeader>
                      <StyledTableCellHeader>
                        Como Conheceu
                      </StyledTableCellHeader>
                      <StyledTableCellHeader>Valor</StyledTableCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.roi?.investiments?.map((investiment, index) => (
                      <TableRow key={index}>
                        <StyledTableCellBody>
                          {investiment?.supplier_name}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {investiment?.knowWay}
                        </StyledTableCellBody>
                        <StyledTableCellBody>
                          {exportFormatedMoney(investiment?.value || 0)}
                        </StyledTableCellBody>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default RoiInformationModal
